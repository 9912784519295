import React, { useState, useRef } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import axios from "axios";
import "./SignUp.css";

const GOOGLE_API_KEY = "AIzaSyBPjaLjrAO_XUHzXQjExbuAfQ-nebRGx4A"; 
const libraries = ["places"]; // Static so that the libraries don't reload

function SellerSignUp() {
  // formData holds the structured data for submission
  const [formData, setFormData] = useState({
    businessName: "",
    email: "",
    businessAddress: {
      streetAddress: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    },
    phone: "",
  });

  // addressInput controls the visible text in the Autocomplete input
  const [addressInput, setAddressInput] = useState("");
  // isAddressValid is true only when a valid address is selected from the suggestions
  const [isAddressValid, setIsAddressValid] = useState(false);

  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");
  const autocompleteRef = useRef(null);

  // Handle changes for all fields.
  // For the address field, update addressInput and reset the validity flag.
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate phone number to allow only numeric values.
    if (name === "phone" && value && !/^\d*$/.test(value)) {
      return;
    }

    // For the address field, update addressInput and reset validity.
    if (name === "businessAddress") {
      setAddressInput(value);
      setIsAddressValid(false);
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // This function extracts a structured address object from the Google Place object.
  const extractAddressDetails = (place) => {
    let address = {
      streetAddress: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    };

    if (place.address_components) {
      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          address.streetAddress = component.long_name + " ";
        }
        if (types.includes("route")) {
          address.streetAddress += component.long_name;
        }
        if (types.includes("locality")) {
          address.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          address.state = component.long_name;
        }
        if (types.includes("postal_code")) {
          address.zipcode = component.long_name;
        }
        if (types.includes("country")) {
          address.country = component.long_name;
        }
      });
    }
    return address;
  };

  // When a place is selected, extract the address details,
  // update formData and addressInput, and mark the address as valid.
  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.address_components) {
        const structuredAddress = extractAddressDetails(place);
        console.log("Extracted Address Details:", structuredAddress);

        setFormData((prevData) => ({
          ...prevData,
          businessAddress: structuredAddress,
        }));
        setAddressInput(place.formatted_address || structuredAddress.streetAddress);
        setIsAddressValid(true);
      }
    }
  };

  // Validate form fields.
  const validateForm = () => {
    const newErrors = {};

    if (formData.businessName.trim().length < 2) {
      newErrors.businessName = "Business name must be at least 2 characters long.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Removed phone requirement validation.
    // if (!formData.phone.trim()) {
    //   newErrors.phone = "Phone number is required.";
    // }

    if (!isAddressValid) {
      newErrors.businessAddress = "Please select a valid address from the suggestions.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission.
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // Send the form data, including the structured address, to your backend.
      await axios.post("http://72.14.182.251/su/api/sellers/signup", formData);

      setStatusMessage("Sign-up successful!");
      setStatusType("success");

      // Reset state on successful submission.
      setFormData({
        businessName: "",
        email: "",
        businessAddress: {
          streetAddress: "",
          city: "",
          state: "",
          zipcode: "",
          country: "",
        },
        phone: "",
      });
      setAddressInput("");
      setIsAddressValid(false);
      setErrors({});
      
    } catch (error) {
      console.error(error);
      setStatusMessage("Sign-up failed. Please try again.");
      setStatusType("error");
    }
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={libraries}>
      <div className="signup-container">
        <form onSubmit={handleSubmit}>
          {/* Business Name Field */}
          <div className="form-group">
            <input
              type="text"
              name="businessName"
              placeholder="Business Name ..."
              value={formData.businessName}
              onChange={handleInputChange}
              required
            />
            {errors.businessName && <span className="error-message">{errors.businessName}</span>}
          </div>

          {/* Email Field */}
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email ..."
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>

          {/* Business Address Field with Google Autocomplete & Manual Input */}
          <div className="form-group">
            <Autocomplete
              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                name="businessAddress"
                placeholder="Enter business address..."
                value={addressInput}
                onChange={handleInputChange}
                required
                style={{ width: "100%", padding: "10px" }}
              />
            </Autocomplete>
            {errors.businessAddress && <span className="error-message">{errors.businessAddress}</span>}
          </div>

          {/* Phone Field (Now Optional) */}
          <div className="form-group">
            <input
              type="tel"
              name="phone"
              placeholder="Phone (optional) ..."
              value={formData.phone}
              onChange={handleInputChange}
              // Removed the required attribute here
            />
            {errors.phone && <span className="error-message">{errors.phone}</span>}
          </div>

          {/* Submit Button */}
          <button type="submit" className="submit-button" style={{ marginTop: 40 }}>
            SUBMIT YOUR INFO
          </button>
        </form>
        {statusMessage && <p className={`status-message ${statusType}`}>{statusMessage}</p>}
      </div>
    </LoadScript>
  );
}

export default SellerSignUp;
