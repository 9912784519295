import React from 'react';
import BuyerSignUp from '../components/BuyerSignUp';

function Buyer() {
    return (
        <div style={{ padding: 10 }}>
            <h1 style={{ color: "#444" }}>WELCOME</h1>
            <p style={{ color: "#666" }}>Add some info to stay connected!</p>
            <BuyerSignUp />
        </div>
    );
}

export default Buyer;
