import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Buyer from './pages/Buyers';
import Seller from './pages/Seller';

function App() {
  return (
    <div className='app-container'>
      <Router>
        <Routes>
          <Route path="/" element={<Buyer />} />
          <Route path="/seller" element={<Seller />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
