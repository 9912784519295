import React from 'react';
import SellerSignUp from '../components/SellerSignUp';

function Seller() {
    return (
        <div style={{ padding: 10 }}>
            <h1 style={{ color: "#444" }}>WELCOME</h1>
            <p style={{ color: "#666" }}>Add some info to stay connected!</p>
            <SellerSignUp />
        </div>
    );
}

export default Seller;
