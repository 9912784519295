import React, { useState } from 'react';
import axios from 'axios';
import './SignUp.css';

function BuyerSignUp() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success' or 'error'

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate phone number to allow only numbers
    if (name === 'phone' && value && !/^\d*$/.test(value)) {
      return; // Do not update if non-numeric input
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate fields
  const validateForm = () => {
    const newErrors = {};

    // Validate first name and last name (at least 2 characters)
    if (formData.firstName.trim().length < 2) {
      newErrors.firstName = 'First name must be at least 2 characters long.';
    }
    if (formData.lastName.trim().length < 2) {
      newErrors.lastName = 'Last name must be at least 2 characters long.';
    }

    // Validate email with regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Removed phone required validation since it's optional
    // if (!formData.phone.trim()) {
    //   newErrors.phone = 'Phone number is required.';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    try {
      // Post data to the API
      await axios.post('http://72.14.182.251/su/api/users/signup', formData);

      setStatusMessage('Sign-up successful!');
      setStatusType('success');
      setFormData({ firstName: '', lastName: '', email: '', phone: '' }); // Clear form fields
      setErrors({});
    } catch (error) {
      console.error(error);
      setStatusMessage('Sign-up failed. Please try again.');
      setStatusType('error');
    }
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit}>
        {/* First Name Field */}
        <div className="form-group">
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name ..."
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
          {errors.firstName && <span className="error-message">{errors.firstName}</span>}
        </div>

        {/* Last Name Field */}
        <div className="form-group">
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name ..."
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
          {errors.lastName && <span className="error-message">{errors.lastName}</span>}
        </div>

        {/* Email Field */}
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email ..."
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>

        {/* Phone Field (Optional) */}
        <div className="form-group">
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone (optional) ..."
            value={formData.phone}
            onChange={handleInputChange}
            // Removed the required attribute
          />
          {errors.phone && <span className="error-message">{errors.phone}</span>}
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button" style={{ marginTop: 40 }}>
          SUBMIT YOUR INFO
        </button>
      </form>
      {statusMessage && (
        <p className={`status-message ${statusType}`}>
          {statusMessage}
        </p>
      )}
    </div>
  );
}

export default BuyerSignUp;
